import React from 'react';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { useLocale } from '@modules';
import { useUserAtom } from '@store';

interface ProtectedRouteComponentProps extends RouteComponentProps {
  component: React.FC<RouteComponentProps>,
}

export const useRedirectInvalidUser = ({ path }: RouteComponentProps) => {
  const user = useUserAtom();
  const locale = useLocale();

  const isBrowser = () => typeof window !== 'undefined';
  if (isBrowser() && !user.token && path !== '/charts/login') {
    navigate(`/${locale}/charts/login`);
    return false;
  }
  if (isBrowser() && user.token && path === '/charts/login') {
    navigate(`/${locale}/charts/`);
    return false;
  }

  return true;
};

const ProtectedRouteAndRedirect = ({ component: Component, ...props }: ProtectedRouteComponentProps) => {
  const isUserValid = useRedirectInvalidUser(props);

  return isUserValid ? <Component {...props} /> : null;
};

export default ProtectedRouteAndRedirect;
