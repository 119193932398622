import React from 'react';
import { Box, Layout } from '@core';
import { ChildrenProps } from '@types';
import { UpdatedDimensionContextProvider } from 'src/store/DimensionContext';
import { ColorContextProvider } from '../../colorpicker';
import { multipleRGBColorObj } from '../helpers';

export const styles = {
  container: {
    'section .content': {
      pt: 'headerHeight',
      minHeight: 'unset !important',
      mt: 0,
    },
  },
};

export const ChartContextWrapper = ({ children }: ChildrenProps) => (
  <ColorContextProvider defaultColors={multipleRGBColorObj}>
    <Layout>
      <Box sx={styles.container}>
        <UpdatedDimensionContextProvider>
          {children}
        </UpdatedDimensionContextProvider>
      </Box>
    </Layout>
  </ColorContextProvider>
);
